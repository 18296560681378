import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import flowers from "./images/bg_flowers.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faCircleInfo, faHome } from '@fortawesome/free-solid-svg-icons'

function App() {

  const [homeVisible, setHomeVisible] = useState<Boolean>(true);
  const [rsvpVisible, setRsvpVisible] = useState<Boolean>(false);
  const [detailsVisible, setDetailsVisible] = useState<Boolean>(false);


  function setHome() {
    setRsvpVisible(false);
    setDetailsVisible(false);
    setHomeVisible(true);
  }


  function setDetails() {
    setRsvpVisible(false);
    setHomeVisible(false);
    setDetailsVisible(true);
  }


  function setRsvp() {
    setDetailsVisible(false);
    setHomeVisible(false);
    setRsvpVisible(true);
  }

  return (
    <div className="flex flex-col h-screen  justify-start items-center">
      {/* <div className="bg-gray-400 w-96 h-96 relative z-0"> */}

      <div className="overflow-hidden h-48 w-full absolute top-0 z-2 ">
        <img src={flowers} className="rotate-[70deg] -mt-[185px] max-w-[400px] sm:-mt-[430px] sm:max-w-[900px]  "></img>
      </div>

      {/* ============== DETAILS ============== */}
      {homeVisible ? (
        <div className={`pt-10 sm:pt-32 md:pt-56 flex flex-col justify-center items-center z-10 text-center`}>

          <div className="mt-12 sm:mt-0">
            TOGETHER<br />
            WITH THEIR FAMILIES
          </div>

          <div className="mt-6">
            <div className="font-['Dancing_Script'] text-4xl sm:text-6xl">Kerry-Lyn Roberts</div>
            <div className="mt-2 mb-1 sm:mt-4 sm:mb-3">AND</div>
            <div className="font-['Dancing_Script'] text-4xl sm:text-6xl">Jourdan Templeton</div>
          </div>

          <div className="mt-8 sm:hidden">
            <div>REQUEST THE PLEASURE OF YOUR</div>
            <div>COMPANY AT THE CEREMONY OF</div>
            <div>THEIR WEDDING</div>
          </div>

          <div className="hidden mt-8 sm:display">
            <div>REQUEST THE PLEASURE OF YOUR COMPANY</div>
            <div>AT THE CEREMONY OF THEIR WEDDING</div>
          </div>

          <div className="mt-6 flex flex-col">
            <div>DEC</div>
            <div className="flex flex-row justify-between justify-center">
              <div className="flex-1 pt-4 sm:pt-5 pb-2 px-2 sm:px-1 min-w-[110px] sm:min-w-[200px] border-t-2 border-b-2 border-black text-right">FRIDAY</div>
              <div className="flex-none px-2 pt-2 sm:pt-1 pb-1 text-4xl sm:text-6xl">15</div>
              <div className="flex-1 pt-4 sm:pt-5 pb-2 px-2 sm:px-1 min-w-[110px] sm:min-w-[200px] border-t-2 border-b-2 border-black text-left">AT 4:00 PM</div>
            </div>
            <div>2023</div>
          </div>
        </div>) : null}


      {/* ============== BUTTONS ============== */}
      <div className="flex flex-col justify-center items-center text-center px-2 mt-4  z-10">
        <div className="flex flex-row">
          {!homeVisible ? <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-4 w-36" onClick={x => setHome()}><FontAwesomeIcon icon={faHome} />&nbsp;&nbsp;&nbsp;HOME</button> : null}
          {!detailsVisible ? <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-4 w-36" onClick={x => setDetails()}><FontAwesomeIcon icon={faCircleInfo} />&nbsp;&nbsp;&nbsp;DETAILS</button> : null}
          {!rsvpVisible ? <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded m-4 w-36" onClick={x => setRsvp()}><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;&nbsp;RSVP</button> : null}
        </div>
      </div>

      {/* ============== RSVP ============== */}
      {rsvpVisible ? (
        <div className={`w-full min-w-[360px] min-h-[800px] z-10 `}>
          <iframe className="w-full h-full min-h-48 border-none" src="https://templeton.rsvpify.com?embed=1&securityToken=vRGvhlHDnn9BbzlNsnVjInJFPuCyFjsP"></iframe>
        </div>) : null}

      {/* ============== DETAILS ============== */}
      {detailsVisible ? (
        <div className={`mb-auto pt-10 sm:pt-32 md:pt-56 flex flex-col justify-center items-center z-10 text-center ${!detailsVisible ? "hidden" : ""}`}>

          <div className="mt-12 sm:mt-0">
            <b>VENUE</b><br />
            <a href="https://the-springs.com.au/" target="_blank" className="underline"><span>THE SPRINGS</span></a><br />
            <a href="https://goo.gl/maps/QtggJC9VtmMYJpSbA" target="_blank" className="hover:underline">1080 PEATS RIDGE ROAD<br />
              PEATS RIDGE NSW 2250</a>
          </div>

          <div className="mt-10">
            <b>SCHEDULE</b><br />
            <ul>
              <li>4:00 PM - Guests seated</li>
              <li>4:30 PM - Ceremony begins</li>
              <li>5:00 PM - Photos & refreshments for guests</li>
              <li>6:30 PM - Reception begins</li>
              <li>10:30 PM - Reception concludes</li>
            </ul>
          </div>

        </div>) : null}

      {/* ============== FOOTER IMAGE ============== */}
      <div className="overflow-hidden h-36 w-full absolute bottom-0 z-1">
        <img src={flowers} className="rotate-[245deg] -mt-[30px] md:-mt-[40px] min-w-[500px] "></img>
      </div>

    </div >
  );
}

export default App;
